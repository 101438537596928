import * as pageConstants from './constants';
import * as dateTools from './dateTools';
import { TariffCalculator_Result, TariffCalculator_DateConversionResult} from './types';

/* Parking cost calculator script for public parking garages in Ingelheim */

/**
  * Function to estimate the parking costs in Ingelheim public parking
 * @param arrivalDate Expected in format YYYY-MM-DD
 * @param arrivalTime Expected in format HH:MM[:SS]
 * @param departureDate Expected in format YYYY-MM-DD
 * @param departureTime Expected in format HH:MM[:SS]
 */
export default function ParkingCostCalculator(
    arrivalDate: string, arrivalTime: string,
    departureDate: string, departureTime: string): TariffCalculator_Result {
  let result: TariffCalculator_Result = {
    missingOrInvalidInputs: [],
    invalidDates: [],
    errorMessage: '',
    errorCode: -1,
    hasError: false,
    cost: -1,
  }
  // check for input values
  result = checkForValues(result, arrivalDate, arrivalTime, departureDate, departureTime);
  if (result.hasError) { return result; }
  // convert dates
  const conversionResult = convertToDates(result, arrivalDate, arrivalTime, departureDate, departureTime);
  if (conversionResult.result.hasError) { return conversionResult.result; }
  const startTimestamp = conversionResult.startTimestamp;
  const endTimestamp = conversionResult.endTimestamp;
  // make sure timeframe is valid
  if (endTimestamp < startTimestamp) {
    result.hasError = true;
    result.errorMessage = 'invalid timespan';
    result.errorCode = 30;
    return result;
  }
  // calculate start after free hour is used up
  const chargeStartTimestamp = dateTools.addHours(startTimestamp, 1);
  // check if below or equal to one hour (which means parking is free)
  if (chargeStartTimestamp >= endTimestamp) {
    result.cost = 0;
    return result;
  }
  // more then one hour, we need to do this more seriously
  let timeToCheck = chargeStartTimestamp;
  let currentTariffZone = 'start';
  let lastTariffZone = '';
  let cost = 0;
  console.log('start calc:');
  console.log(timeToCheck);
  if (checkIfDateIsInBlockTarriff(timeToCheck)) {
    currentTariffZone = 'block';
  }
  // check loop for calculation
  // ! to avoid float errors we calculate 10 times the amount to stay in integers
  while (timeToCheck < endTimestamp) {
    // check if in block
    // do first price calculation
    // if there is a change since the last
    if (lastTariffZone !== currentTariffZone) {
      //console.log('zone.change');
      // do money adding
      switch(currentTariffZone) {
        case 'start': // < 5 hours
          cost += 2;
          break;
        case 'park': // >= 5 hours
          cost += 10;
          break;
        default: // block
          cost += 5;
      }
      // update last zone
      lastTariffZone = currentTariffZone;
    } else { // no zone change since last run
      if (currentTariffZone !== 'block') {
        switch(currentTariffZone) {
          case 'start': // < 5 hours
            cost += 2;
            break;
          default: // >= 5 hours
            cost += 10;
            break;
        }
      }
    }

    // increase for the next check
    timeToCheck = dateTools.addMinutes(timeToCheck, 30);
    // calculate new zone - is it below or above 4 hours?
    if (timeToCheck < dateTools.addHours(chargeStartTimestamp, 4)) {
      currentTariffZone = 'start';
    } else {
      currentTariffZone = 'park';
    }
    // check if we entered the block zone
    if (checkIfDateIsInBlockTarriff(timeToCheck)) {
      currentTariffZone = 'block';
    }
    //console.log(cost);
  }
  console.log('exit time');
  console.log(timeToCheck);
  // do final calculation step


  //The getDay() method returns the day of the week (from 0 to 6) for the specified date. Note: Sunday is 0, Monday is 1, and so on.
  //pageConstants.publicHolidays_RhinelandPalatinate
  // start at the time of the end of the free parking
  //
  // check where you are to find out the tariff for the current 30 minute block
  // add the cost to the sum (if in new "zone / time")
  // go through in 30 minute steps until you are past the end

  // save result in return object (convert back from 10 times integer value to float)
  result.cost = (cost / 10);
  // return result
  return result;
}



function checkForValues(result: TariffCalculator_Result,
    arrivalDate: string, arrivalTime: string,
    departureDate: string, departureTime: string) {
  if (arrivalDate === '') { result.hasError = true; result.missingOrInvalidInputs.push('arrivalDate'); }
  if (arrivalTime === '') { result.hasError = true; result.missingOrInvalidInputs.push('arrivalTime'); }
  if (departureDate === '') { result.hasError = true; result.missingOrInvalidInputs.push('departureDate'); }
  if (departureTime === '') { result.hasError = true; result.missingOrInvalidInputs.push('departureTime'); }
  if (result.hasError) {
    result.errorMessage = 'missing inputs';
    result.errorCode = 10;
  }
  return result;
}



function convertToDates(result: TariffCalculator_Result,
    arrivalDate: string, arrivalTime: string,
    departureDate: string, departureTime: string) {
  const conversion: TariffCalculator_DateConversionResult = {
    startTimestamp: new Date(Date.now()),
    endTimestamp: new Date(Date.now()),
    result: result,
  }
  try {
    conversion.startTimestamp = dateTools.dayAndTimeLocalToDate(arrivalDate, arrivalTime);
  } catch (error) {
    conversion.result.hasError = true;
    conversion.result.invalidDates.push('start');
  }
  try {
    conversion.endTimestamp = dateTools.dayAndTimeLocalToDate(departureDate, departureTime);
  } catch (error) {
    conversion.result.hasError = true;
    conversion.result.invalidDates.push('end');
  }
  if (conversion.result.hasError) {
    conversion.result.errorMessage = 'invalid dates';
    conversion.result.errorCode = 20;
  }
  return conversion;
}

function checkIfDateIsInBlockTarriff(timestamp: Date): boolean {
  const day = timestamp.getDay();
  //console.log(day);
  //const dateComponent = new Date(Date.UTC(timestamp.getFullYear(),timestamp.getMonth(),timestamp.getDate(),0,0,0,0));
  //console.log(dateComponent);

  //console.log(timestamp.getHours());



  // ToDo: make sure that we treat a day before holiday like saturday and a day after like monday
  // => check "yesterday", "today" and "tomorrow"

  if (day === 0) { // sunday / holiday
    //console.log('sunday');
    return true;
  } else if (day === 1) { // monday / day after public holiday
    if (timestamp.getHours() < 8) { // if before 8:00 it is block
      //console.log('monday morning');
      return true;
    }
  } else if (day === 6) { // saturday / day before public holiday
    if (timestamp.getHours() >= 22) { // if past 22:00 it is block
      //console.log('saturday evening');
      return true;
    }
  } else { // regular day, still check for night tariff
    if (timestamp.getHours() < 8 || timestamp.getHours() >= 22) { // if before 8:00 or after 20:00 it is block
      //console.log('night');
      return true;
    }
  }

  // nothing matched, so not block
  return false;
}



/*

Zone start : 0,20 per 30 minutes
Zone park : 1,00 per 30 minutes
Zone block : 0,50 per block


mo-sa 8-22
- 1 hour free
- start 2 - until 4 end : ,2 per 30 min
- start 5: 1 per 30 min

night mon to fr 22 till 8
1 hour free
per night 0,5

sat 22 til mon 8 (or on public holidays)

1 hour free
per night 0,5

first hour free, rest by time-zones




*/
