import React from 'react';
import { PageProps } from "gatsby";
import ParkingCostCalculator from '../../../utils/parkingCostCalculator';
import Layout from '../../../components/layout/layout';
import ParkingTariffSign from '../../../components/parkingTariffSign/parkingTariffSign';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'en';

  function calculateParkingCost() {
    const dateStart = (document.getElementById('dateStart') as HTMLInputElement).value;
    const timeStart = (document.getElementById('timeStart') as HTMLInputElement).value;
    const dateEnd = (document.getElementById('dateEnd') as HTMLInputElement).value;
    const timeEnd = (document.getElementById('timeEnd') as HTMLInputElement).value;
    const result = ParkingCostCalculator(dateStart, timeStart, dateEnd, timeEnd);
    if (result.hasError) {
      alert('ERROR! ' + result.errorMessage);
    } else {
      alert(result.cost);
    }

  }


  return (
    <Layout language={language} title="Parking cost" description="Estimate your parking cost for Ingelheim" path={props.path} previewimage={previewImage} hasLanguageCounterpart={false}>
      <h1>Parking cost estimator</h1>
      <h3>My town is a nice place, but estimating parking fees can be quite hard.</h3>

      <p>
        Ingelheim - where I live - has a good amount of parking facilities (at street level and undergroound).
        And while I think they are reasonable priced it can be quite tricky to get an idea what it will cost you to park
        your car there on a weekend or for more then a day.
      </p>

      <p>When you are at the mentioned parking facilities you will see a tariff information board looking like this:</p>

      <ParkingTariffSign language={language}/>

      <p>
        Estimating the prices for more then a few hours is super easy, isn't it? ;-)
        So to have a more lazy way to estimate (e.g. for visitors at my place) I created a small script.
      </p>

      <p>
        Feel free to play with it, but keep in mind that this is not an official tool and only for estimation purposes.
        Actual charges from the payment machines may differ...
      </p>

    <div>
      <p>Arrival:</p>
      <div style={{padding: '10px', backgroundColor: '#fff', display:'inline-block', borderRadius: '10px'}}>
        <input type="date" id="dateStart" style={{fontSize: '20px', border: '0'}} />
        <input type="time" id="timeStart" style={{fontSize: '20px', border: '0'}} />
      </div>
    </div>

    <div>
      <p>Departure:</p>
      <div style={{padding: '10px', backgroundColor: '#fff', display:'inline-block', borderRadius: '10px'}}>
        <input type="date" id="dateEnd" style={{fontSize: '20px', border: '0'}} />
        <input type="time" id="timeEnd" style={{fontSize: '20px', border: '0'}} />
      </div>
    </div>

    <div style={{paddingTop: '20px'}}>
      <button style={{fontSize: '20px', padding: '10px', borderRadius: '10px', border: '1px solid black'}} onClick={() => calculateParkingCost()}>run calculation</button>
    </div>

    </Layout>
  );
}

