/* File with date related functions */

/**
 * Add a certain amount of hours to a date
 * @param originalTime The date (& time) to start with
 * @param hoursToAdd The hours to add to the original time
 */
export function addHours(originalTime: Date, hoursToAdd: number): Date {
  return new Date(originalTime.getTime() + (hoursToAdd * 60 * 60 * 1000));
}

/**
 * Add a certain amount of minutes to a date
 * @param originalTime The date (& time) to start with
 * @param minutesToAdd The minutes to add to the original time
 */
export function addMinutes(originalTime: Date, minutesToAdd: number): Date {
  return new Date(originalTime.getTime() + (minutesToAdd * 60 * 1000));
}

/**
 * Add a certain amount of seconds to a date
 * @param originalTime The date (& time) to start with
 * @param secondsToAdd The seconds to add to the original time
 */
export function addSeconds(originalTime: Date, secondsToAdd: number): Date {
  return new Date(originalTime.getTime() + (secondsToAdd * 1000));
}

/**
 * Combine a date-string with a time-string into a date object, treating it as a local time
 * @param dayDate Expected in format YYYY-MM-DD
 * @param time Expected in format HH:MM[:SS]
 */
export function dayAndTimeLocalToDate(dayDate: string, time: string): Date {
  return new Date(dayDate + 'T' + time);
}

/**
 * * Combine a date-string with a time-string into a date object, treating it as a UTC time
 * @param dayDate Expected in format YYYY-MM-DD
 * @param time Expected in format HH:MM[:SS]
 */
export function dayAndTimeUtcToDate(dayDate: string, time: string): Date {
  return new Date(dayDate + 'T' + time + 'Z');
}

/**
 *
 * @param date1
 * @param date2
 */
export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate())
  ;
}
