import React from 'react';
//import { Link } from 'gatsby';
//import * as pageConstants from '../../utils/constants';
import '../../styles/components/parkingTariffSign.scss';

export default function ParkingTariffSign(language) {
  const texts = [];
  texts['de'] = {
    dayTariff: 'Tagtarif',
    monToFri: 'montags bis samstags von 08:00 Uhr bis 22:00 Uhr',
    firstHour: '1. Stunde',
    free: 'entgeltfrei',
    everyFollowing30Mins: 'jede weitere angefangene 1/2 Stunde',
    untilFinishedFourth: 'bis zu vollendeten 4. Stunde',
    fromFifthHour: 'ab der 5. Stunde',
    everyStarted30Mins: 'jede weitere angefangene 1/2 Stunde',
    nightTariff: 'Nachttarif',
    monToFriNight: 'montags bis freitags von 22:00 Uhr bis 08:00 Uhr',
    onPublicHolidays: '(bei Feiertagen bis zum darauf folgenden Werktag 08:00 Uhr)',
    satToMon: 'samstags von 22:00 Uhr bis montags 08:00 Uhr',
    afterThatPerNight: 'danach bis 08:00 Uhr, pro Nacht',
    priceOne: '0,20 Euro',
    priceTwo: '0,50 Euro',
    priceThree: '1,00 Euro',
  };
  texts['en'] = {
    dayTariff: 'Day Tariff',
    monToFri: 'Monday to Saturday from 08:00 until 22:00',
    firstHour: '1st hour',
    free: 'free',
    everyFollowing30Mins: 'every following started 1/2 hour',
    untilFinishedFourth: 'until the finished 4th hour',
    fromFifthHour: 'from 5th hour',
    everyStarted30Mins: 'every started 1/2 hour',
    nightTariff: 'Night Tariff',
    monToFriNight: 'Monday to Friday from 22:00 until 08:00',
    onPublicHolidays: '(on public holidays until the next business day 08:00)',
    satToMon: 'Saturdays from 22:00 until Monday 08:00',
    afterThatPerNight: 'after that until 08:00, per night',
    priceOne: '0.20 Euro',
    priceTwo: '0.50 Euro',
    priceThree: '1.00 Euro',
  };
  let textLang = 'en';
  if (language === 'de') {
    textLang = 'de';
  }


  return (
    <div className="parkingTariffSignBoard">
      <div className="parkingTariffSignFrame">

        <div className="parkingTariffHeadline">{texts[textLang].dayTariff}</div>
        <div className="parkingTariffSubHeadline">{texts[textLang].monToFri}</div>

        <div className="parkingTariffLineSpacer"></div>

        <div className="parkingTariffDetailInfo">{texts[textLang].firstHour}</div>
        <div className="parkingTariffPricingInfo">{texts[textLang].free}</div>

        <div className="parkingTariffDetailInfo">
          {texts[textLang].everyFollowing30Mins}
          <div className="parkingTariffFinePrintLine">
            {texts[textLang].untilFinishedFourth}
          </div>
        </div>
        <div className="parkingTariffPricingInfo">{texts[textLang].priceOne}</div>

        <div className="parkingTariffDetailInfo">
          {texts[textLang].fromFifthHour}
          <div className="parkingTariffFinePrintLine">
            {texts[textLang].everyStarted30Mins}
          </div>
        </div>
        <div className="parkingTariffPricingInfo">{texts[textLang].priceThree}</div>

        <div className="parkingTariffLineSpacer"></div>

        <div className="parkingTariffHeadline">{texts[textLang].nightTariff}</div>
        <div className="parkingTariffSubHeadline">{texts[textLang].monToFriNight}</div>
        <div className="parkingTariffSubHeadline">{texts[textLang].onPublicHolidays}</div>

        <div className="parkingTariffLineSpacer"></div>

        <div className="parkingTariffDetailInfo">{texts[textLang].firstHour}</div>
        <div className="parkingTariffPricingInfo">{texts[textLang].free}</div>

        <div className="parkingTariffDetailInfo">{texts[textLang].afterThatPerNight}</div>
        <div className="parkingTariffPricingInfo">{texts[textLang].priceTwo}</div>

        <div className="parkingTariffLineSpacer"></div>

        <div className="parkingTariffSubHeadline">{texts[textLang].satToMon}</div>
        <div className="parkingTariffSubHeadline">{texts[textLang].onPublicHolidays}</div>

        <div className="parkingTariffLineSpacer"></div>

        <div className="parkingTariffDetailInfo">{texts[textLang].firstHour}</div>
        <div className="parkingTariffPricingInfo">{texts[textLang].free}</div>

        <div className="parkingTariffDetailInfo">{texts[textLang].afterThatPerNight}</div>
        <div className="parkingTariffPricingInfo">{texts[textLang].priceTwo}</div>

      </div>

    </div>
  );
}
